#create-row {
    margin-top: 10em;
    justify-content: center;
}

#create-row > div {
    display: flex;
    flex-direction: column;
}

#create-row > div > img {
    width: min(796px, calc((0.62917333333333 * 100vw) + (54.20464 * 1px)));
    height: 517px;
}

#create-description-col {
    max-width: 30%;
    margin-top: 40px;
}

#create-description-col.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#create-description-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#create-image-col {
    margin-left: -4em;
    margin-top: 2.5em;
}

#create-image-col.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(5%);
    transition: all 600ms;
}

#create-image-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media (max-width: 1100px) {
    #create-row {
        flex-direction: column-reverse;
        align-items: center;
    }

    #create-row > div {
        width: 90%;
    }

    #create-row > div > img {
        width: 100%;
        height: auto;
    }

    #create-description-col {
        max-width: 80%;
        margin-top: 0;
    }

    #create-image-col {
        margin: 0;
    }
}
