#loading-overlay-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background-color: #f2f2fc;
}

#loading-overlay {
    position: absolute;
    width: 100%;
    height: auto;
}

.loading-fadein {
    -webkit-animation: fadein .1s linear forwards;
    animation: fadein .1s linear forwards;
}

.loading-fadeout {
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}

@media only screen and (max-aspect-ratio: 2000/993) {
    #loading-overlay {
        width: auto;
        height: 100%;
    }
}

@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; display: none }
}

@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; display: none }
}
