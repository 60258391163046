#footer-background {
    background-color: #7273f1;
}

#footer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 1em;
}

#footer-row > div {
    display: flex;
    align-items: center;
}

#footer-row > div > p {
    display: block;
    font-size: 1em;
    margin-left: 80px;
}

#footer-row > div > a > img {
    height: 30px;
    width: auto;
    padding: 0 0.5em;
}

@media (max-width: 1100px) {
    #footer-row > div > p {
        margin-left: 1vw;
    }
}

@media (max-width: 600px) {
    #footer-row > div > p {
        display: none;
    }
}
