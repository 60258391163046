#collaborate-row-background {
    background-image: url("../../assets/images/collaborate-background.svg");
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: max(1440px, 100%) 100%;
}

#collaborate-row {
    margin-top: -8em;
    align-items: center;
    justify-content: center;
    height: 75em;
}

#collaborate-row > div {
    display: flex;
    flex-direction: column;
    padding: 0 2em;
}

#collaborate-row > div > img {
    width: 562px;
    height: 515px;
}

#collaborate-description-col {
    max-width: 30%;
}

#collaborate-description-col.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#collaborate-description-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#collaborate-image-col.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(-5%);
    transition: all 600ms;
}

#collaborate-image-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media (max-width: 1100px) {
    #collaborate-row-background {
        background-position: right center;
    }

    #collaborate-row {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10em;
        height: auto;
        padding: 25em 0;
    }

    #collaborate-row > div {
        width: 80%;
    }

    #collaborate-row > div > img {
        width: 100%;
        height: auto;
    }

    #collaborate-image-col {
        margin-top: -25em;
    }

    #collaborate-description-col {
        margin-top: 2em;
        max-width: 80%;
    }
}
