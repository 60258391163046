#gallery-container {
    margin-top: min(100px, 20vw);
    -webkit-animation: fadein .1s linear forwards;
    animation: fadein .1s linear forwards;
}

#gallery-empty-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

#gallery-empty-container > a {
    width: min(275px, 34vw);
    height: min(64px, 8vw);
    text-align: center;
    line-height: 2.9em;
}

#discover-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    -webkit-animation: slidedown .15s linear forwards;
    animation: slidedown .15s linear forwards;
}

#discover-container > h2 {
    font-family: Mulish, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: min(54px, 6.575vw);
    line-height: 125%;
    text-align: center;
    color: #7273F1;
}

#gallery-page-background-image {
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: -1;
    left: 0;
    right: auto;
    top: 0;
    width: max(1398px, 98%);
    height: 924px;
}

.gallery-image-container {
    display: block;
    height: 215px;
    background: rgba(226, 226, 255, 0.4);
    border-radius: 8px;
}

.gallery-image {
    width: calc(100% - 20px);
    height: 195px;
    object-fit: cover;
    object-position: 25% 25%;
    margin: 10px;
    border-radius: 4px;
}

@media (max-width: 1240px) {
    #gallery-page-background-image {
        left: auto;
        right: -173px;
    }
}

@-webkit-keyframes slidedown {
    0% { margin-bottom: 20px; }
    100% { margin-bottom: 0; }
}

@keyframes slidedown {
    0% { margin-bottom: 20px; }
    100% { margin-bottom: 0; }
}
