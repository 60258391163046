#ambassadors-row {
    justify-content: center;
}

#ambassadors-row > div {
    display: flex;
    flex-direction: column;
}

#ambassadors-row > div > img {
    width: min(796px, calc((0.62917333333333 * 100vw) + (54.20464 * 1px)));
    height: 517px;
}

#ambassadors-description-col {
    max-width: 32%;
    margin-top: 60px;
}

#ambassadors-description-col.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#ambassadors-description-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#ambassadors-description-col > a {
    width: min(201px, 25.125vw);
    height: min(64px, 8vw);
    margin-top: 3.5em;
    text-align: center;
    line-height: 2.9em;
}

#ambassadors-image-col.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(5%);
    transition: all 600ms;
}

#ambassadors-image-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media (max-width: 1100px) {
    #ambassadors-row {
        flex-direction: column-reverse;
        align-items: center;
    }

    #ambassadors-row > div {
        width: 80%;
    }

    #ambassadors-row > div > img {
        width: 100%;
        height: auto;
    }

    #ambassadors-description-col {
        max-width: 80%;
    }

    #ambassadors-image-col {
        margin: 0;
    }
}
