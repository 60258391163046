#earn-row {
    margin-top: -8em;
    justify-content: flex-end;
}

#earn-row > div {
    display: flex;
    flex-direction: column;
}

#earn-row > div > img {
    width: min(723px, calc((0.54003947368421 * 100vw) - (41.227618421053 * 1px)));
    height: 451px;
}

#earn-description-col {
    max-width: 40%;
}

#earn-description-col.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#earn-description-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#earn-image-col {
    margin-left: 2em;
    margin-top: -2em;
}

#earn-image-col.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(5%);
    transition: all 600ms;
}

#earn-image-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media (max-width: 1100px) {
    #earn-row {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: -10em;
    }

    #earn-row > div {
        width: 90%;
    }

    #earn-row > div > img {
        width: 100%;
        height: auto;
    }

    #earn-description-col {
        max-width: 80%;
    }

    #earn-image-col {
        margin: 0;
    }
}
