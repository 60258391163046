#header-row-background {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    left: min(0px, calc(100vw - 1620px));
    right: auto;
    width: max(1322px, calc((0.50350877192982 * 100vw) + (423.64210526316 * 1px)));
    height: 920px;
    top: 0;
}

#header-row {
    align-items: stretch;
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;
}

#header-row > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

#header-description-col {
    position: absolute;
    left: max(calc(50% - 540px), 58px);
    margin-top: 0;
    margin-left: 0;
    width: auto;
    max-width: 50%;
}

#header-description-col > h1 {
    margin: 0;
    width: auto;
    height: 126px;
    min-width: 380px;
}

#header-description-col > h3 {
    margin-top: 9.5em;
    width: auto;
    max-width: 100%;
    min-width: 420px;
}

#header-description-col > p {
    margin-top: -40px;
    max-width: 566px;
    width: auto;
}

#header-description-col > a {
    width: min(201px, 25.125vw);
    height: min(64px, 8vw);
    margin-top: 3.5em;
    text-align: center;
    line-height: 2.9em;
}

#header-description-col.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#header-description-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#header-image-col-lg {
    display: block;
}

#header-image-col-lg > img {
    display: block;
    margin-top: 8em;
    padding-bottom: 10em;
    padding-left: 0;
    overflow: hidden;
    width: 639px;
    height: 650px;
    max-width: 80vw;
    min-width: 480px;
}

#header-image-col-lg.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(5%);
    transition: all 600ms;
}

#header-image-col-lg.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#header-image-col-sm {
    display: none;
}

#header-image-col-sm > img {
    display: none;
    margin-top: 8em;
    padding-bottom: 10em;
    padding-left: 0;
    overflow: hidden;
    width: auto;
    height: auto;
    max-width: 80vw;
}

#header-image-col-sm.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(5%);
    transition: all 600ms;
}

#header-image-col-sm.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media (max-width: 1780px) {
    #header-row-background {
        left: auto;
        right: calc(50vw - 445px);
    }
}

@media (max-width: 1618px) {
    #header-row-background {
        right: calc(0.05 * 100vw + 280px);
    }
}

@media (max-width: 1400px) {
    #header-description-col > h3 {
        max-width: 90%;
    }
}

@media (max-width: 1120px) {
    #header-description-col > h3 {
        max-width: 80%;
    }

    #header-description-col > h1 {
        max-width: 95%;
    }
}

@media (max-width: 1020px) {
    #header-row {
        padding-bottom: 16em;
    }

    #header-row-background {
        top: -4em;
    }

    #header-description-col {
        left: 5vw;
    }

    #header-description-col > h3 {
        margin-top: 11em;
    }

    #header-description-col > p {
        margin-top: 9em;
        width: 88vw;
        max-width: 88vw;
    }

    #header-description-col > a {
        margin-top: 1.7em;
    }

    #header-image-col-lg > img {
        margin-top: 4em;
    }
}

/* 798px -> 600px view width range for image resize */
@media (max-width: 798px) {
    #header-row {
        padding-bottom: calc((0.31707317073171 * 100vw) - (50.487804878049 * 1px));
    }

    #header-row-background {
        top: calc((-0.1 * 100vw) + (39 * 1px));
        right: calc((0.28205128205128 * 100vw) - (12.820512820513 * 1px));
        width: 165.8vw;
        height: auto;
        aspect-ratio: 1322/920;
    }

    #header-description-col > h1 {
        min-width: 60vw;
        max-width: 60vw;
    }

    #header-description-col > h3 {
        margin-top: calc((0.28532608695652 * 100vw) + (107.3097826087 * 1px));
        min-width: 60vw;
        max-width: 60vw;
    }

    #header-description-col > p {
        margin-top: calc((0.37383177570093 * 100vw) - (78.317757009346 * 1px));
        min-width: 80vw;
        max-width: 80vw;
    }

    #header-image-col-lg {
        display: none;
    }

    #header-image-col-lg > img {
        display: none;
    }

    #header-image-col-sm {
        display: block;
    }

    #header-image-col-sm > img {
        display: block;
        margin-right: -13.75vw;
        margin-top: 8em;
        overflow-x: hidden;
    }
}

#header-title {
    position: relative;
    display: inline-block;
}

#header-title > div {
    font-size: 0;
    opacity: 0;
    position: absolute;
    top: 1px;
    z-index: 1000;
}

#header-title > div:nth-child(1) {
  animation: header-title-roll 10s linear infinite 0s;
}
#header-title > div:nth-child(2) {
  animation: header-title-roll 10s linear infinite 1.5s;
}
#header-title > div:nth-child(3) {
  animation: header-title-roll 10s linear infinite 3s;
}
#header-title > div:nth-child(4) {
  animation: header-title-roll 10s linear infinite 4.5s;
}
#header-title > div:nth-child(5) {
  animation: header-title-roll 10s linear infinite 6s;
}
#header-title > div:nth-child(6) {
  animation: header-title-explode 10s linear infinite 7.5s;
}

@keyframes header-title-roll {
    0% {
        font-size: 0;
        opacity: 0;
        margin-left: -30px;
        margin-top: 0;
        transform: rotate(-25deg);
    }
    1.5% {
        opacity: 1;
    }
    2.5% {
        font-size: inherit;
        opacity: 1;
        margin-left: 0;
        margin-top: 0;
        transform: rotate(0deg);
    }
    10% {
        font-size: inherit;
        opacity: 1;
        margin-left: 0;
        margin-top: 0;
        transform: rotate(0deg);
    }
    13.5% {
        font-size: 0;
        opacity: 0.5;
        margin-left: min(30px, calc(4.5751633986928vw - (6.5098039215686 * 1px)));
        margin-top: min(40px, calc(5.2287581699346vw - (1.7254901960784 * 1px)));
    }
    100% {
        font-size: 0;
        opacity: 0;
        margin-left: -30px;
        margin-top: 0;
        transform: rotate(15deg);
    }
}

@keyframes header-title-explode {
    0% {
        font-size: 0;
        opacity: 0;
        margin-left: -30px;
        margin-top: 0;
        transform: rotate(-25deg);
    }
    1.5% {
        opacity: 1;
    }
    2.5% {
        font-size: inherit;
        opacity: 1;
        margin-left: 0;
        margin-top: 0;
        transform: rotate(0deg);
    }
    15% {
        font-size: inherit;
        opacity: 1;
        margin-left: 0;
        margin-top: 0;
        transform: rotate(0deg);
    }
    18.5% {
        font-size: 200px;
        opacity: 0;
        margin-left: -300px;
        margin-top: -100px;
    }
    100% {
        font-size: 0;
        opacity: 0;
        margin-left: -30px;
        margin-top: 0;
        transform: rotate(15deg);
    }
}
