#nft-marketplace-row-background {
    background-image: url("../../assets/images/nft-marketplace-background.png"),
                      linear-gradient(0deg, rgba(114,115,241,1) 71.9%, transparent 71.9%, transparent 71.9%);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top right;
}

#nft-marketplace-row {
    align-items: center;
    justify-content: center;
    padding-top: 18em;
    padding-bottom: 4em;
}

#nft-marketplace-row > div {
    display: flex;
    flex-direction: column;
}

#nft-marketplace-row > div > img {
    width: 702px;
    height: 429px;
}

#nft-marketplace-description-col {
    max-width: 30%;
}

#nft-marketplace-description-col.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#nft-marketplace-description-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#nft-marketplace-image-col.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(-5%);
    transition: all 600ms;
}

#nft-marketplace-image-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media (max-width: 1100px) {
    #nft-marketplace-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #nft-marketplace-row > div {
        width: 80%;
    }

    #nft-marketplace-row > div > img {
        width: 100%;
        height: auto;
    }

    #nft-marketplace-description-col {
        margin-top: 2em;
    }

    #nft-marketplace-description-col {
        max-width: 80%;
    }
}
