#powered-by-row {
    align-items: center;
    justify-content: center;
}

#powered-by-row > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#powered-by-row > div.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#powered-by-row > div.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#powered-by-row > div > p > a > img {
    max-width: 100%;
    margin-top: -3em;
}

@media (max-width: 1100px) {
    #powered-by-row > div {
        width: 80%;
    }
}

@media (max-width: 680px) {
    #powered-by-row {
        margin-top: 0;
    }

    #powered-by-row > div > p > a > img {
        margin-top: 0;
    }
}
