#pending-line-background {
    position: absolute;
    left: 0;
    right: auto;
    bottom: 0;
    width: 90vw;
    height: auto;
    z-index: -2;
    -o-object-fit: contain;
    object-fit: contain;
}

#pending-yellow-background {
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    width: 90%;
    height: auto;
    z-index: -1;
    -o-object-fit: contain;
    object-fit: contain;
}

#pending-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

#pending-container > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

#pending-container > div > h2 {
    font-family: Mulish, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 125%;
    text-align: center;
    color: #a93c24;
}

#pending-container > div > p {
    font-family: Metropolis, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 173%;
    text-align: center;
    color: #f5f5f5;
    padding-top: 40px;
}

#timer-container {
    border: 2px solid #ffffff;
    border-radius: 159px;
    padding: 20px 18px;
    display: flex;
    gap: 15px;
    color: #ffffff;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

#timer-container > div {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Metropolis, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: 0.01em;
    -webkit-animation: fadein .1s linear forwards;
    animation: fadein .1s linear forwards;
    overflow-x: clip;
}

.timer-container-loaded {
    animation: timerContainerGrow 1s;
}

@keyframes timerContainerGrow {
    from {
        max-width: 14px;
    }
    to {
        max-width: 500px;
    }
}

@media only screen and (max-aspect-ratio: 19/12) {
    #pending-line-background {
        left: auto;
        right: 10vw;
        width: auto;
        height: 82vh;
    }

    #pending-yellow-background {
        left: 10vw;
        right: auto;
        width: auto;
        height: 98vh;
    }
}

@media only screen and (max-aspect-ratio: 1/1) {
    #pending-line-background {
        left: auto;
        right: -40vw;
    }

    #pending-yellow-background {
        left: -10vw;
        right: auto;
        height: 110vh;
    }
}

@media only screen and (max-width: 991px) {
    #pending-container > div > h2 {
        font-size: 45px;
    }

    #timer-container > div {
        font-size: 24px;
    }
}

@media only screen and (max-width: 570px) {
    #pending-container > div {
        flex-direction: column;
        gap: 0;
    }
}

@media only screen and (max-width: 480px) {
    #pending-container > div > h2 {
        font-size: 36px;
    }

    #timer-container > div {
        font-size: 22px;
    }

    #timer-container {
        padding: 15px 15px;
    }
}
