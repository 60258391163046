#roadmap-row > #roadmap-content-row {
    display: flex;
    justify-content: center;
}

#roadmap-row > #roadmap-header.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(-20%);
    transition: all 600ms;
}

#roadmap-row > #roadmap-header.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#roadmap-row > #roadmap-content-row > #roadmap-col-left > .hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateX(-2%);
    transition: all 600ms;
}

#roadmap-row > #roadmap-content-row > #roadmap-col-left > .show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#roadmap-row > #roadmap-content-row > #roadmap-col-mid.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#roadmap-row > #roadmap-content-row > #roadmap-col-mid.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#roadmap-row > #roadmap-content-row > #roadmap-col-right > .hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateX(2%);
    transition: all 600ms;
}

#roadmap-row > #roadmap-content-row > #roadmap-col-right > .show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}
