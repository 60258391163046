#create-collaborate-earn-row {
    align-items: center;
    justify-content: center;
    margin-top: -5em;
}

#create-collaborate-earn-row > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#create-collaborate-earn-row > div > img {
    max-width: 100%;
}

#create-collaborate-earn-row > .hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(5%);
    transition: all 600ms;
}

#create-collaborate-earn-row > .show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#create-collaborate-earn-row > .hidden:nth-child(1) {
    transition-delay: 100ms;
}

#create-collaborate-earn-row > .hidden:nth-child(2) {
    transition-delay: 200ms;
}

#create-collaborate-earn-row > .hidden:nth-child(3) {
    transition-delay: 300ms;
}

#create-icon-col > img {
    padding: 0.44em 2.72em;
    width: 284px;
    height: 300px;
}

#create-icon-col > h3 {
    margin-right: 0.5em;
}

#collaborate-icon-col > img {
    padding: 0 2em;
    width: 307px;
    height: 314px;
}

#earn-icon-col > img {
    padding: 1.035em 2.595em;
    width: 288px;
    height: 281px;
}

#earn-icon-col > h3 {
    margin-right: 0.5em;
}

@media (max-width: 1100px) {
    #create-collaborate-earn-row > div {
        width: 80%;
    }

    #create-collaborate-earn-row > div > img {
        width: 80%;
        height: auto;
    }

    #collaborate-icon-col > img {
        padding: 0.697em 2em;
    }
}
