#draw-canvas-parent {
    width: calc(100vw - 58px);
    height: 100vh;
    margin-top: 0;
    margin-left: 58px;
    background: rgba(226, 226, 255, 0.4);
    overflow: hidden;
}

@media (max-width: 649px) {
    #draw-canvas-parent {
        width: 100vw;
        height: calc(100vh - 54px);
        margin-top: 54px;
        margin-left: 0;
    }
}

#draw-canvas-container {
    width: 100%;
    height: 100%;
}

#draw-menu-container {
    position: absolute;
    overflow: hidden;
    z-index: 100000;
    top: 0;
    left: 0;
}

#draw-hamburger-input {
    display: none;
}

#draw-hamburger-input:checked ~ #draw-hamburger-label > i {
    background-color: transparent;
    transform: rotate(90deg);
}

#draw-hamburger-input:checked ~ #draw-hamburger-label > i:before {
    transform: translate(-50%, 0%) rotate(45deg);
}

#draw-hamburger-input:checked ~ #draw-hamburger-label > i:after {
    transform: translate(-50%, 0%) rotate(-45deg);
}

#draw-hamburger-label {
    z-index: 9999;
    position: relative;
    display: block;
    height: 50px;
    width: 50px;
}

#draw-hamburger-label:hover {
    cursor: pointer;
}

#draw-hamburger-label > i {
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    top: 50%;
    background-color: #222;
    pointer-events: auto;
    transition-duration: 0.35s;
    transition-delay: 0.35s;
}

#draw-hamburger-label > i:before, #draw-hamburger-label > i:after {
    position: absolute;
    border-radius: 2px;
    display: block;
    width: 100%;
    height: 2px;
    left: 50%;
    background-color: #222;
    content: "";
    transition: transform 0.35s;
    transform-origin: 50% 50%;
}

#draw-hamburger-label > i:before {
    transform: translate(-50%, -14px);
}

#draw-hamburger-label > i:after {
    transform: translate(-50%, 14px);
}

#draw-menu expand:hover, #draw-menu li:hover {
    background-color: #5f60ef;
}

#draw-menu {
    position: fixed;
    height: 54px;
    width: 100vw;
    background-color: #7273F1;
    display: flex;
    flex-direction: column;
    transition: height 246ms 0.5s ease;
    padding-top: 54px;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    z-index: 1;
}

@media (min-width: 650px) {
    #draw-menu {
        height: 100vh;
        width: 58px;
        transition: width 246ms 0.5s ease;
    }
}

#draw-menu ul {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
}

#draw-menu li {
    position: relative;
    cursor: pointer;
}

#draw-menu li a,
#draw-menu li button {
    background: none;
    border: none;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: white;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 17px;
}

#draw-menu > ul > li > a > img,
#draw-menu > ul > li > a > svg {
    height: 26px;
    flex-shrink: 0;
    width: 20px;
    margin-right: 5px;
    padding: 14px 23px 14px 19px;
    color: #e6e6e6;
    color: rgba(255, 255, 255, 0.9);
}

#draw-menu > ul > li > button > img {
    height: 20px;
    flex-shrink: 0;
    width: 20px;
    margin-right: 5px;
    padding: 17px 23px 17px 19px;
    color: #e6e6e6;
    color: rgba(255, 255, 255, 0.9);
}

#draw-hamburger-input {
    display: none;
}

#draw-hamburger-input:checked ~ #draw-menu {
    height: 100vh;
}

@media (min-width: 650px) {
    #draw-hamburger-input:checked ~ #draw-menu {
        width: 275px;
    }
}

#draw-hamburger-input:checked ~ #draw-hamburger-label > i {
    background-color: transparent;
    transform: rotate(90deg);
}

#draw-hamburger-input:checked ~ #draw-hamburger-label > i:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

#draw-hamburger-input:checked ~ #draw-hamburger-label > i:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

#draw-hamburger-label {
    z-index: 9999;
    position: relative;
    display: block;
    height: 24px;
    width: 24px;
}

#draw-hamburger-label:hover {
    cursor: pointer;
}

#draw-hamburger-label > i {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    pointer-events: auto;
    transition-duration: 0.35s;
    transition-delay: 0.35s;
}

#draw-hamburger-label > i:before, #draw-hamburger-label > i:after {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    content: "";
    transition: transform 0.35s;
    transform-origin: 50% 50%;
}

#draw-hamburger-label > i:before {
    transform: translate(-50%, -7px);
}

#draw-hamburger-label > i:after {
    transform: translate(-50%, 7px);
}

#draw-hamburger-label {
    position: fixed;
    top: 14px;
    left: 17px;
}
