body {
  margin: 0;
  font-family: Metropolis, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  overflow-x: hidden;
}

#root {
  width: 100vw;
  overflow-x: hidden;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  width: min(90%, 1440px);
  margin: 0 auto;
  min-width: 320px;
  max-width: 1440px;
}

section {
  display: flex;
}

.rgb-green {
  color: #2ecaac;
}

.rgb-orange {
  color: #ff8a70;
}

.rgb-pink {
  color: #e55a9d;
}

.rgb-light-blue {
  color: #f2f2fc;
}

.rgb-blue {
  color: #7273f1;
}

.rgb-purple {
  color: #905def;
}

.rgb-white {
  color: #ffffff;
}

.rgb-light-grey {
  color: #efefef;
}

.rgb-black {
  color: #373737;
}

.rarible-yellow {
  color: #feda03;
}

h1 {
  font-family: Metropolis, sans-serif;
  font-weight: 900;
  font-size: min(63px, 7.875vw);
}

h2 {
  font-family: Metropolis, sans-serif;
  font-weight: 700;
  font-size: min(41px, 5.125vw);
}

h3 {
  font-family: Metropolis, sans-serif;
  font-weight: 700;
  font-size: min(30px, 3.75vw);
}

h4 {
  font-family: Metropolis, sans-serif;
  font-weight: 700;
  font-size: min(37px, 4.625vw);
}

a {
  cursor: pointer;
}

p {
  margin: 0;
  font-family: Metropolis, sans-serif;
  font-weight: 500;
  font-size: min(22px, 2.75vw);
  line-height: min(35px, 4.375vw);
}

.rgb-button-blue {
  display: block;
  background: #7273f1;
  box-shadow: 0 4px 20px rgba(39, 0, 127, 0.4);
  border-radius: 32px;
  border: none;
  font-family: Metropolis, sans-serif;
  font-weight: 500;
  font-size: min(22px, 2.75vw);
  line-height: min(35px, 4.375vw);
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.rgb-button-green {
  display: block;
  background: #2ecaac;
  box-shadow: 0 4px 20px rgba(18, 81, 69, 0.4);
  border-radius: 32px;
  border: none;
  font-family: Metropolis, sans-serif;
  font-weight: 500;
  font-size: min(22px, 2.75vw);
  line-height: min(35px, 4.375vw);
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.text-input {
  display: block;
  background: #ffffff;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  padding-left: 16px;
  font-family: Metropolis, sans-serif;
  font-weight: 400;
  font-size: min(18px, 2.25vw);
  line-height: min(35px, 4.375vw);
  color: #544837;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media(prefers-reduced-motion) {
  .hidden {
    transition: none !important;
  }
}

@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Thin.otf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ThinItalic.otf');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraLight.otf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraLightItalic.otf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Light.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Regular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-RegularItalic.otf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-SemiBoldItalic.otf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Bold.otf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-BoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraBold.otf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraBoldItalic.otf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Black.otf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-RegularItalic.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-Black.ttf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Mulish;
  src: url('./assets/fonts/Mulish-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
