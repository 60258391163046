#win-row-background {
    background-image: url("../../assets/images/win-background.png"),
    linear-gradient(180deg, rgba(144,93,239,1) 71.6%, transparent 71.6%, transparent 71.6%);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom left;
}

#win-row {
    align-items: center;
    justify-content: center;
    padding-bottom: 16em;
}

#win-row > div {
    display: flex;
    flex-direction: column;
}

#win-description-col {
    max-width: 30%;
}

#win-description-col.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateY(2%);
    transition: all 600ms;
}

#win-description-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#win-image-col {
    margin-top: -2em;
    margin-left: 4em;
}

#win-image-col.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(5%);
    transition: all 600ms;
}

#win-image-col.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

@media (max-width: 1100px) {
    #win-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 2em;
        padding-bottom: 18em;
    }

    #win-row > div {
        width: 90%;
    }

    #win-image-col {
        margin-top: 2em;
        margin-left: 0;
    }

    #win-description-col {
        max-width: 80%;
    }
}
