.spinner-black {
    animation: rotate 2s linear infinite;

    & .path {
        stroke: rgba(0, 0, 0, 0.35);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

.spinner-white {
    animation: rotate 2s linear infinite;

    & .path {
        stroke: #ffffff;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
