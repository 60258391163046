#tokenomics-row-background {
    background-image: url("../../assets/images/tokenomics-background.svg");
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: max(1440px, 100%) 100%;
}

#tokenomics-row > #tokenomics-header-and-image.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateX(-2%);
    transition: all 600ms;
}

#tokenomics-row > #tokenomics-header-and-image.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#tokenomics-row > #tokenomics-content-row {
    display: flex;
    justify-content: center;
}

#tokenomics-row > #tokenomics-content-row > div.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateX(2%);
    transition: all 600ms;
}

#tokenomics-row > #tokenomics-content-row > div.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
    transition-delay: 0.3s;
}

#tokenomics-row > #tokenomics-content-row > div > div.hidden {
    opacity: 0;
    filter: blur(1px);
    transform: translateX(2%);
    transition: all 600ms;
}

#tokenomics-row > #tokenomics-content-row > div > div.show {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
    transition-delay: 0.3s;
}
