#subscribe-background {
    background-color: #FAFAFA;
}

#subscribe-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5em;
    padding-bottom: 5em;
}

#subscribe-row > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#subscribe-row > form > h4 {
    margin-top: 0.6em;
}

#subscribe-row > form > .text-input {
    margin-top: 0.4em;
    width: min(450px, 80vw);
    height: 40px;
}

#subscribe-row > form > .button {
    margin-top: 3em;
    width: min(201px, 25.125vw);
    height: min(64px, 8vw);
}
